// 订单相关路由
import { lazy } from 'react'

export const SimulatedAccount = lazy(() => import('../pages/account/simulatedAccount'));
export const CreateAccount = lazy(() => import('../pages/account/createAccount'));
export const ModelDetail = lazy(() => import('../pages/account/modelDetail'));

const orderRoutes = [
  {
    name: '策略模拟',
    path: '/account/simulatedAccount',
    component: SimulatedAccount,
  },
  {
    name: '创建策略模拟',
    path: '/account/createAccount',
    component: CreateAccount,
  },
  {
    name: '策略模拟明细',
    path: '/account/modelDetail',
    component: ModelDetail,
  },
]

export default orderRoutes