import { createSlice } from '@reduxjs/toolkit'
import { TYPES } from '../pages/model/filterStock/utils/constant'
const SELECT_INT = {
  index: {},
  indexInfo: {}
}
const filterStock = createSlice({
  name: 'filter-stock',
  initialState: {
    actionList: [
      {
        name: '量化模型',
        key: TYPES.MODEL,
        showField: 'name',
        data: [
          // {
          //   id: '10012',
          //   condition: [],
          //   key: 'NW_10012',
          //   name: 'NW_10012',
          //   value: ['12'],
          //   selectType: 'single',
          // },
          // {
          //   id: '10014',
          //   condition: [],
          //   key: 'NW_10014',
          //   name: 'NW_10014',
          //   value: ['10014'],
          //   selectType: 'single',
          // },
          // {
          //   id: '10016',
          //   condition: [],
          //   key: 'NW_10016',
          //   name: 'NW_10016',
          //   value: ['10016'],
          //   selectType: 'single',
          // },
          // {
          //   id: '10017',
          //   condition: [],
          //   key: 'NW_10017',
          //   name: 'NW_10017',
          //   value: ['10017'],
          //   selectType: 'single',
          // },
          // {
          //   id: '10018',
          //   condition: [],
          //   key: 'NW_10018',
          //   name: 'NW_10018',
          //   value: ['10018'],
          //   selectType: 'single',
          // },
          // {
          //   id: '10019',
          //   condition: [],
          //   key: 'NW_10019',
          //   name: 'NW_10019',
          //   value: ['10019'],
          //   selectType: 'single',
          // },
        ]
      },
      {
        name: '风险因子',
        key: TYPES.R_F,
        showField: 'name',
        data: [
          // {
          //   id: 'NW_RF_0001',
          //   key: 'nw_rf_01',
          //   condition: [],
          //   value: ['NW_RF_0001'],
          //   selectType: 'single',
          // },
          // {
          //   id: 'NW_RF_0002',
          //   condition: [],
          //   key: 'nw_rf_02',
          //   value: ['NW_RF_0002'],
          //   selectType: 'single',
          // },
          // {
          //   id: 'NW_RF_0003',
          //   key: 'nw_rf_03',
          //   condition: [],
          //   value: ['NW_RF_0003'],
          //   selectType: 'single',
          // },
        ]
      },
      {
        name: '数据过滤',
        key: TYPES.LIMIT,
        showField: 'name',
        data: [
          {
            id: 'NW_PLAT_TOTAL_0001',
            condition: [
              {
                name: '0~200',
                id:1,
                value: [0,200],
              },
              {
                name: '200~400',
                id: 2,
                value: [200,400]
              },
              {
                name: '400~600',
                id: 3,
                value: [400,600]
              },
              {
                name: '600~800',
                id: 4,
                value: [600,800]
              },
              {
                name: '800~1000',
                id: 5,
                value: [800,1000]
              },
              {
                name: '1000~1200',
                id: 6,
                value: [1200,1400]
              },
              {
                name: '1200~1400',
                id: 7,
                value: [1200, 1400]
              },
              {
                name: '1600~1800',
                id: 8,
                value: [1600, 1800]
              },
              {
                name: '1800~2000',
                id: 9,
                value: [1800, 2000]
              },
              {
                name: '2000~2200',
                id: 10,
                value: [2000, 2200]
              },
              {
                name: '2200~2400',
                id: 11,
                value: [2200, 2400]
              },
              {
                name: '2400~2600',
                id: 12,
                value: [2400, 2600]
              },
              {
                name: '2600~2800',
                id: 13,
                value: [2600, 2800]
              },
              {
                name: '2800~3000',
                id: 14,
                value: [2800, 3000]
              },
              {
                name: '3000~3200',
                id: 15,
                value: [3000, 3200]
              },
              {
                name: '自定义',
                id: 16,
                value: [],
                definedTye: 'numberr-limit'
              }
            ],
            name: '索引分段',
            key: 'indexLimit',
            selectType: 'single',
          },
          {
            id: 'NW_PLAT_TOTAL_0002',
            condition: [
              {
              },
            ],
            name: '板块过滤',
            key: 'platFilter',
            selectType: 'single',
          },
          {
            id: 'NW_PLAT_TOTAL_0003',
            condition: [
              {
                name: '股价范围',
                id: 8,
                value: [],
                definedTye: 'numberr-limit'
              }
            ],
            name: '股价过滤',
            key: 'stockPrice',
            selectType: 'single',
          }
          // {
          //   id: 'NW_PLAT_TOTAL_0003',
          //   condition: [
          //     {
          //     },
          //   ],
          //   name: '大盘过滤',
          //   key: 'indexShanghai',
          //   // selectType: 'single',
          // },
        ]
      },
      {
        name: '基本面',
        key: TYPES.BASE,
        showField: 'name',
        data: [
          {
            id: 'NW_RF_0001',
            condition: [
              {
                name: '亏损',
                id: 1,
                value: [-1]
              },
              {
                name: '0~10',
                id: 2,
                value: [0,10],
              },
              {
                name: '0~20',
                id: 3,
                value: [0,20]
              },
              {
                name: '0~50',
                id: 4,
                value: [0,50]
              },
              {
                name: '0~100',
                id: 5,
                value: [0,100]
              },
              {
                name: '>100',
                id: 6,
                value: [100, '>']
              },
              {
                name: '自定义',
                id: 7,
                value: [],
                definedTye: 'numberr-limit'
              }
            ],
            name: '市盈率',
            subName: 'TTM',
            key: 'ttm',
            selectType: 'single',
          },
          {
            id: 'NW_RF_0002',
            condition: [
              {
                name: '0~3',
                id: 1,
                value: [0,3],
              },
              {
                name: '3~5',
                id: 2,
                value: [3,5]
              },
              {
                name: '5~10',
                id: 3,
                value: [5,10]
              },
              {
                name: '10~15',
                id: 4,
                value: [10,15]
              },
              {
                name: '15~20',
                id: 5,
                value: [15,20]
              },
              {
                name: '>20',
                id: 6,
                value: [20,'>']
              },
              {
                name: '>30',
                id: 7,
                value: [30,'>']
              },
              {
                name: '自定义',
                id: 8,
                value: [],
                definedTye: 'numberr-limit'
              }
            ],
            name: '换手率',
            key: 'turnoverRateF',
            selectType: 'single',
          },
          {
            id: 'NW_RF_0003',
            condition: [
              {
                name: '<25亿',
                id: 1,
                value: ['<',25]
              },
              {
                name: '<50亿',
                id: 2,
                value: ['<',50],
              },
              {
                name: '<100亿',
                id: 3,
                value: ['<',100]
              },
              {
                name: '>100亿',
                id: 4,
                value: [100,'>']
              },
              {
                name: '>1000亿',
                id: 5,
                value: [1000,'>']
              },
              {
                name: '自定义',
                id: 6,
                value: [],
                definedTye: 'numberr-limit'
              }
            ],
            name: '总市值',
            key: 'totalMv',
            selectType: 'single',
          },
        ]
      },
      {
        name: '技术面',
        key: TYPES.FORM,
        showField: 'name',
        data: [
          {
            id: 'NW_BASE_0001',
            condition: [
              {
                name: '金叉',
                id: 'macdGood',
                field: 'macdGood',
                value: [1],
              },
              {
                name: '死叉',
                id: 'macdBad',
                field: 'macdBad',
                value: [1]
              },
              {
                name: '白线0轴之上',
                id: 'macdDifUp0',
                field: 'macdDifUp0',
                value: [1]
              },
              {
                name: '白线0轴之下',
                id: 'macdDifUp0_down',
                field: 'macdDifUp0',
                value: [0]
              },
              {
                name: '白在黄线之上',
                id: 'macdDifUpDea',
                field: 'macdDifUpDea',
                value: [1]
              },
              {
                name: '白在黄线之下',
                id: 'macdDifUpDea_down',
                field: 'macdDifUpDea',
                value: [0]
              },
            ],
            name: 'MACD',
            key: 'macd',
            selectType: 'single',
          },
          {
            id: 'NW_BASE_0002',
            condition: [
              {
                name: '大于0',
                id: 'netMfVol1',
                field: 'netMfVol',
                value: [1],
              },
              {
                name: '小于0',
                id: 'netMfVol0',
                field: 'netMfVol',
                value: [0],
              },
            ],
            name: '当日净值',
            key: 'netMfVol',
            selectType: 'single',
          },
          {
            id: 'NW_BASE_0002',
            condition: [
              {
                name: '多头排列',
                id: 'avgSort1',
                field: 'avgGoodSort',
                value: [1],
              },
              {
                name: '空头排列',
                id: 'avgSort2',
                field: 'avgBadSort',
                value: [1],
              },
            ],
            name: '多空排列',
            key: 'avgSort',
            selectType: 'single',
          },
          {
            id: 'NW_BASE_0002',
            condition: [
              {
                name: '早晨之星',
                id: 'morningStar',
                field: 'morningStar',
                value: [1],
              },
              {
                name: '红三兵',
                id: 'red3Soldier',
                field: 'red3Soldier',
                value: [1],
              },
              {
                name: '双响炮',
                id: 'cannon2',
                field: 'cannon2',
                value: [1],
              },
              {
                name: '长下影线',
                id: 'longLowerShadow',
                field: 'longLowerShadow',
                value: [1],
              },
              {
                name: '一根大阳线',
                id: 'bigWhiteCandlestick',
                field: 'bigWhiteCandlestick',
                value: [1],
              },
            ],
            name: 'K线形态',
            key: 'kStatus',
          },
          // {
          //   id: 'NW_BASE_0010',
          //   condition: [
          //     {
          //       name: '一日净流入',
          //       id: 'beiRatio1',
          //       field: 'beiAddRatioDays',
          //       value: [1],
          //     },
          //     {
          //       name: '两日净流入',
          //       id: 'beiRatio2',
          //       field: 'beiAddRatioDays',
          //       value: [2],
          //     },
          //     {
          //       name: '三日净流入',
          //       id: 'beiRatio3',
          //       field: 'beiAddRatioDays',
          //       value: [3],
          //     },
          //   ],
          //   name: '北向资金',
          //   key: 'beiAddRatioDays',
          //   selectType: 'single',
          // },
        ]
      }
    ],
    select: SELECT_INT,
    filter: {}
  },
  reducers: {
    setSelect(state, action) {
      state.select = action.payload || []
    },
    clearSelect(state, action) {
      state.select = SELECT_INT
    },
    setFilter(state, action) {
      const { filter, select } = state
      const { value } = action.payload || {}
      const { index, indexInfo } = select
      const { key: pKey} = indexInfo || {}
      const { key: sKey} = index || {}
      const newFilter = {...filter}
      const pValue = filter[pKey] || {...indexInfo}
      if(!pValue?.value){
        pValue.value = []
      }

      const sValue = pValue.value?.find(item => {
        return item.key === sKey
      }) || {...index}

      sValue.value = value || []

      const sValueNotCur = pValue?.value?.filter(item => item.key !== sKey) || []

      sValueNotCur.push(sValue)

      newFilter[pKey] = {
        ...pValue,
        value: sValueNotCur
      }
      state.filter = newFilter
    },
    setNotConditionFilter(state, action) {
      const { filter, select } = state
      const { value, itemInfo } = action.payload || {}
      const { indexInfo } = itemInfo || select
      const { key: pKey} = indexInfo || {}
      const pValue = filter[pKey] || {...indexInfo}
      if(!pValue?.value){
        pValue.value = []
      }
      const isHas = pValue.value?.some(item => item.id === value.id)
      let newValue = [...pValue.value]
      if(isHas) {
        newValue = newValue.filter((item => item.id !== value.id))
      } else {
        newValue.push(value)
      }
      state.filter[pKey] = {
        ...pValue,
        value: newValue
      }
    },
    clearFilter(state, action) {
      state.filter = {}
    },
    setModelList(state, action) {
      const { actionList } = state
      const value = action.payload || []
      const newValue = value.map(item => {
        return {
          ...item,
          key: `NW_${item.id}`,
          name: `NW_${item.id}`,
          value: [item.id],
          condition: item.condition || [],
          selectType: 'single',
        }
      })
      const list = actionList.map(item => {
        if(item.key === TYPES.MODEL){
          return {
            ...item,
            data: newValue
          }
        }
        return {
          ...item
        }
      })
      state.actionList = [...list]
    },
    setFilterItem(state, action) {
      const { actionList } = state
      const value = action.payload || []
      const newValue = value.map(item => {
        return {
          ...item,
          id: `NW_${item.itemCode}`,
          key: `NW_${item.itemCode}`,
          name: `${item.itemName}`,
          value: [item.itemCode],
          condition: item.condition || [],
          selectType: 'single',
        }
      })
      const list = actionList.map(item => {
        if(item.key === TYPES.R_F){
          return {
            ...item,
            data: newValue
          }
        }
        return {
          ...item
        }
      })
      state.actionList = [...list]
    }
  },
})

export const { 
  setSelect, 
  clearSelect, 
  setFilter, 
  clearFilter,
  setNotConditionFilter,
  setModelList,
  setFilterItem
} = filterStock.actions

export default filterStock.reducer
