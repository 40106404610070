import { inApp } from './helper';

let appInfo = {}
// 调用app sdk
window.stockAppBindFunction = {};
const channel = 'common';

const getConfig = (callbackName, params = {}) => {
  return {
    channel: channel,
    params: {
      callbackName,
      ...params,
    },
  };
};
const nativeCommon = {
  logout: (params, cb) => {
    const callbackName = 'logout';
    const config = getConfig(callbackName, params);
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },

  showToast: (params, cb) => {
    const callbackName = 'showToast';
    const config = getConfig(callbackName, params);
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },

  showWarnToast: (params, cb) => {
    const callbackName = 'showWarnToast';
    const config = getConfig(callbackName, params);
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },

  setTitle: (title, cb) => {
    if (!inApp) {
      document.title = title;
      return;
    }
    const callbackName = 'setTitle';
    const config = getConfig(callbackName, { title });
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },
  // 打开新页面, 带返回按钮
  push: (path, params, cb) => {
    const callbackName = 'push';
    const config = getConfig(callbackName, { path, query: params });
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },
  // 打开新页面, 上个路由被替换, 返回进上上个页面
  pushAndReplace: (path, params, cb) => {
    const callbackName = 'pushAndReplace';
    const config = getConfig(callbackName, { path, query: params });
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },
  // 打开新页面, 无返回按钮
  pushAndRemove: (path, params, cb) => {
    const callbackName = 'pushAndRemove';
    const config = getConfig(callbackName, { path, query: params });
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },
  pop: (params, cb) => {
    const callbackName = 'pop';
    const config = getConfig(callbackName, { query: params });
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },
  // {
  //   tabs: [{
  //     name: '11',
  //     value: 1
  //   }],
  //   callback: 'onSelfWebviewTabChange'
  // },
  setTab: (params, onWebviewTabChange) => {
    const callbackName = 'setTab';
    const config = getConfig(callbackName, { query: params });
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
        } else {
          reject();
        }
      };

      window.stockAppBindFunction[params.callback] = function(...arg) {
        onWebviewTabChange && onWebviewTabChange(...arg);
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },

  // 搜索回调 searchCallBack
  searchCallBack: (cb) => {
    const callbackName = 'searchCallBack';
    window.stockAppBindFunction[callbackName] = function(...arg) {
      cb && cb(...arg);
    };
  },

  // 支付宝支付
  // sign String(支付签名) onResult  Function 结果回调
  aliPay: (sign, onResult) => {
    if (!sign || typeof sign !== 'string') return;
    const callbackName = 'aliPay';
    const config = getConfig(callbackName, { sign });

    return new Promise((resolve, reject) => {
      // 监听调用app事件
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, error } = result;
        if (code !== 'ok') {
          reject(error);
        }
      };

      // 监听支付
      window.stockAppBindFunction.onAliPay = (result) => {
        const { code, data } = result;
        if (code === 'ok') {
          // 支付结果 status 1:成功, 2:失败, 3:未知错误, 4: 没安装支付宝
          resolve(data);
          onResult && onResult(data);
        } else {
          reject('支付失败');
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },

  // 微信支付
  // appId: orderInfo['appid'],
  // partnerId: orderInfo['partnerid'],
  // prepayId: orderInfo['prepayid'],
  // packageValue: orderInfo['package'],
  // nonceStr: orderInfo['noncestr'],
  // timeStamp: int.parse(orderInfo['timestamp']),
  // sign: orderInfo['paySign'],
  wxPay: (params, onResult) => {
    if (Object.keys(params || {}).length === 0) return;

    const callbackName = 'wxPay';
    const config = getConfig(callbackName, { query: params });

    return new Promise((resolve, reject) => {
      // 监听调用app事件
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, error } = result;
        if (code !== 'ok') {
          reject(error);
        }
      };

      // 监听支付
      window.stockAppBindFunction.onWxPay = (result) => {
        const { code, data } = result;
        if (code === 'ok') {
          // 支付结果 status 1:成功, 2:取消支付, 3:未知错误, 4: 没安装微信
          resolve(data);
          onResult && onResult(data);
        } else {
          reject('支付失败');
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },

  // 签署协议
  agree: (params, cb) => {
    const callbackName = 'agree';
    const config = getConfig(callbackName, { query: params });
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      window[channel] && window[channel]?.postMessage(JSON.stringify(config));
    });
  },

  // 选择相片
  // config = {
  //   maxSize //文件最大 M int, 默认: null 不限制
  //   minSize //文件最xi小 M int 默认: null 不限制
  //   accept //可接收文件 array ['png'] 默认: null 不限制
  //   maxCount //最大可选择多少个 默认: 1 单张选择
  // }
  selectImage: (config = {}, cb) => {
    const callbackName = 'selectImage';
    const configs = getConfig(callbackName, { config });
    window.stockAppBindFunction[callbackName] = (result) => {
      const { code, error } = result;
      if (code !== 'ok') {
        cb && cb({}, error);
      }
    };
    // 上传异步回调, data => status:1上传中, 2:上传成功，3:上传失败，4:取消，5：无权限，6:其他原因失败
    window.stockAppBindFunction.onImageUpload = (result) => {
      const { code, data, error } = result;
      if (code === 'ok') {
        cb && cb(data, null);
      } else {
        cb && cb({}, error);
      }
    };
    window[channel] && window[channel]?.postMessage(JSON.stringify(configs));
  },
  // 页面返回回调用
  onPagePop: (cb) => {
    // 注册事件
    const callbackName = 'onPagePop';
    // pop返回之后调用事件
    const popLoadName = 'popLoadName';
    const configs = getConfig(callbackName, { popLoadName });

    window.stockAppBindFunction[callbackName] = (result) => {};
    // 页面返回实行
    window.stockAppBindFunction[popLoadName] = (result) => {
      cb && cb();
    };
    window[channel] && window[channel]?.postMessage(JSON.stringify(configs));
  },
  // 更新app
  updateApp: (cb) => {
    // 注册事件
    const callbackName = 'updateApp';
    // pop返回之后调用事件
    const popLoadName = 'updateApp';
    const configs = getConfig(callbackName, { popLoadName });

    window.stockAppBindFunction[callbackName] = (result) => {};
    // 页面返回实行
    window.stockAppBindFunction[popLoadName] = (result) => {
      cb && cb();
    };
    window[channel] && window[channel]?.postMessage(JSON.stringify(configs));
  },

  // 更新app
  getAppInfo: (cb) => {
    if(Object.keys(appInfo).length) return appInfo
    // 获取用户数据
    const callbackName = 'getAppInfo';
    const config = getConfig(callbackName);
    return new Promise((resolve, reject) => {
      window.stockAppBindFunction[callbackName] = (result) => {
        const { code, data, error } = result;
        if (code === 'ok') {
          resolve(data);
          appInfo  = data
          cb && cb(data, null);
        } else {
          cb && cb(null, error);
          reject(error);
        }
      };
      try {
        window[channel] && window[channel]?.postMessage(JSON.stringify(config));
      } catch (e) {}
    });
    
  },
};

export default nativeCommon;
