import React, { Suspense, useEffect, useRef } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import allRoute from './route';
import { useDispatch } from 'react-redux';
import nativeCommon from 'src/utils/nativeCommon';
import { setAppInfo } from 'src/reducer/root'
import useNavbar from 'src/hooks/useNavbar'
import { setCacheData } from 'src/utils/storage'
import './App.scss';

const Background = (props) => {
  const { scrollEl, handleScroll } = useNavbar()
  const dispatch = useDispatch()
  useEffect(() => {
    nativeCommon.getAppInfo().then(data => {
      dispatch(setAppInfo(data))
      setCacheData('STOCK_APP_INFO_MESSAGE',data)
    })
  },[])
  
  return <div className='shares-app-background' 
    onScroll={handleScroll} 
    ref={scrollEl}
  >{props.children}</div>;
};

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Background>
          <Routes>
            {allRoute.map((route) => {
              return (
                <Route
                  path={route.path}
                  key={route.path}
                  element={<route.component />}
                  name={route.name}
                />
              );
            })}
          </Routes>
        </Background>
      </Suspense>
    </Router>
  );
}

export default App;
