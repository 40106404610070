import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"; 
import { setAppBar } from 'src/reducer/root'
function useNavbar(conf) {
  const { appInfo, appBar } = useSelector(state => state.root)
  const { initAlpha, disHeight = 0, maxAlpha = 1} = appBar
  const { navHeight = 47, barHeight = 0 } = appInfo

  const [alpha, setAlpha] = useState(initAlpha)
  const scrollEl = useRef(null)
  const navEl = useRef(null)

  const totalHeight = useMemo(() => {
    return barHeight + navHeight
  },[appInfo])

  useEffect(() => {
    handleAppBar(initAlpha)
  },[initAlpha])

  const dispatch = useDispatch()
  
  const handleAppBar = (alpha) => {
    dispatch(setAppBar({
      ...appBar,
      alpha,
      height: totalHeight,
    }))
    setAlpha(alpha)
  }
  // 透明导航栏
  const handleScroll = () => {
    if (!scrollEl.current) return
    const scrollTop = scrollEl.current.scrollTop
    // 页面滚动高度小于导航高度
    if (
      scrollTop > 0 &&
      scrollTop >= scrollEl.current.scrollHeight - window.screen.height + disHeight
    ) {
      handleAppBar(maxAlpha)
      return
    }
    if (scrollTop < (totalHeight + disHeight)) {
      if(scrollTop < disHeight) {
        handleAppBar(initAlpha)
      } else {
        handleAppBar((maxAlpha / (totalHeight)) * (scrollTop - disHeight))
      }
    } else {
      handleAppBar(maxAlpha)
    }
  }

  return { alpha, height: totalHeight, scrollEl, navEl, handleScroll }
}

export default useNavbar
