// 订单相关路由
import { lazy } from 'react'

export const GroupDetail = lazy(() => import('../pages/model/groupStockDetail'))
export const FilterStock = lazy(() => import('../pages/model/filterStock'))
export const CreateModel = lazy(() => import('../pages/model/createModel'))
export const SubModelDetail = lazy(() => import('../pages/model/subModelDetail'))
export const Recommend = lazy(() => import('../pages/model/recommend'))

const orderRoutes = [
  {
    name: '策略组合',
    path: '/index/groupDetail',
    component: GroupDetail
  },
  {
    name: '量化分析',
    path: '/index/filterStock',
    component: FilterStock
  },
  {
    name: '策略创建',
    path: '/index/modelCreate',
    component: CreateModel
  },
  {
    name: '策略详情',
    path: '/index/subModelDetail',
    component: SubModelDetail
  },
  {
    name: '筛选推荐',
    path: '/index/recommend',
    component: Recommend
  },
]

export default orderRoutes