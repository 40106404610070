// 股票相关路由
import { lazy } from 'react'

export const StockDetail = lazy(() => import('../pages/stock/stockDetail'))
export const StockDetailNew = lazy(() => import('../pages/stock/stockDetailNew'))
export const SelfStock = lazy(() => import('../pages/stock/selfStock'))
export const EditStockList = lazy(() => import('../pages/stock/editStockList'))
export const GroupDetail = lazy(() => import('../pages/stock/groupDetail'))
export const GroupRecord = lazy(() => import('../pages/stock/groupRecord'))
export const StockRecord = lazy(() => import('../pages/stock/stockRecord'))

const StockRoutes = [
  {
    name: '详情',
    path: '/stock/stockDetailOld',
    component: StockDetail
  },
  {
    name: '详情',
    path: '/stock/stockDetail',
    component: StockDetailNew
  },
  {
    name: '自选',
    path: '/stock/selfStock',
    component: SelfStock
  },
  {
    name: '自选管理',
    path: '/stock/editStockList',
    component: EditStockList
  },
  {
    name: '分组详情',
    path: '/stock/groupDetail',
    component: GroupDetail
  },
  {
    name: '分组操作记录',
    path: '/stock/groupRecord',
    component: GroupRecord
  },
  {
    name: '分组操作记录',
    path: '/stock/stockRecord',
    component: StockRecord
  }
]

export default StockRoutes