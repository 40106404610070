import stockRoute from './stock'
import commonRoutes from './common'
import userRoutes from './user'
import orderRoutes from './order'
import activityRoutes from './activity'
import modelRoutes from './model'
import accountRoute from './account'

const allRoute = [
  ...stockRoute,
  ...commonRoutes,
  ...userRoutes,
  ...orderRoutes,
  ...activityRoutes,
  ...modelRoutes,
  ...accountRoute
]

export default allRoute