import { configureStore } from '@reduxjs/toolkit'
import filterStock from './filterStock'
import root from './root'
const store = configureStore({
  reducer: {
    root,
    filterStock
  }
})

export default store