// 订单相关路由
import { lazy } from 'react'

export const OrderCreate = lazy(() => import('../pages/order/orderCreate'))
export const OrderDetail = lazy(() => import('../pages/order/orderDetail'))
export const PayResult = lazy(() => import('../pages/order/payResult'))

const orderRoutes = [
  {
    name: '订单结算',
    path: '/order/orderCreate',
    component: OrderCreate
  },
  {
    name: '支付结果',
    path: '/order/payResult',
    component: PayResult
  },
  {
    name: '订单详情',
    path: '/order/detail',
    component: OrderDetail
  }
]

export default orderRoutes