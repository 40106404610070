import { createSlice } from '@reduxjs/toolkit'
import { getCacheData } from 'src/utils/storage'
const root = createSlice({
  name: 'root',
  initialState: {
    appInfo: getCacheData('STOCK_APP_INFO_MESSAGE') || {},
    appBar: {
      height: 103,
      alpha: 1,
      maxAlpha: 1,
      disHeight: 10,
      initAlpha: 0
    },
  },
  reducers: {
    setAppInfo(state, action) {
      state.appInfo = action.payload || []
    },
    setAppBar(state, action) {
      state.appBar = action.payload || []
    },
    setAppBarByKey(state, action) {
      const {key, value} = action.payload
      state.appBar[key] = value
    },
  },
})

export const { 
  setAppInfo,
  setAppBar
} = root.actions

export default root.reducer
