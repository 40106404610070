import { lazy } from 'react';
// 股票相关路由
export const User = lazy(() => import('../pages/user/userCenter'));
export const UserCoupon = lazy(() => import('../pages/user/userCoupon'));
export const MyAssistance = lazy(() => import('../pages/user/myAssistance'));
export const Member = lazy(() => import('../pages/user/member'));
export const UserOrderList = lazy(() => import('../pages/user/userOrderList'));
export const MyIntegral = lazy(() => import('../pages/user/myIntegral'));
export const Feedback = lazy(() => import('../pages/user/feedback'));
export const Setting = lazy(() => import('../pages/user/setting'));
export const MyAgree = lazy(() => import('../pages/user/myAgree'));
export const MyAgreeDetail = lazy(() => import('../pages/user/agreeDetail'));
export const EditUserInfo = lazy(() => import('../pages/user/editUserInfo'));
export const ModelList = lazy(() => import('../pages/user/modelList'));
export const ClearUser = lazy(() => import('../pages/user/clearUser'));

const userRoutes = [
  {
    name: '用户详情',
    path: '/user',
    component: User,
  },
  {
    name: '我的优惠券',
    path: '/user/coupon',
    component: UserCoupon,
  },
  {
    name: '我的助力',
    path: '/user/assistance',
    component: MyAssistance,
  },
  {
    name: '会员中心',
    path: '/user/member',
    component: Member,
  },
  {
    name: '我的订单',
    path: '/user/orderList',
    component: UserOrderList,
  },
  {
    name: '我的积分',
    path: '/user/integral',
    component: MyIntegral,
  },
  {
    name: '反馈',
    path: '/user/feedback',
    component: Feedback,
  },
  {
    name: '账户设置',
    path: '/user/setting',
    component: Setting,
  },
  {
    name: '我的协议',
    path: '/user/myAgree',
    component: MyAgree,
  },
  {
    name: '协议详情',
    path: '/user/myAgree/myAgreeDetail',
    component: MyAgreeDetail,
  },
  {
    name: '修改用户信息',
    path: '/user/edit/info',
    component: EditUserInfo,
  },
  {
    name: '我的策略',
    path: '/user/model/list',
    component: ModelList,
  },
  {
    name: '注销用户',
    path: '/user/clear',
    component: ClearUser,
  },
];

export default userRoutes;
