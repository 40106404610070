import { lazy } from 'react';
// 公共相关路由
export const Agree = lazy(() => import('../pages/common/agree'))
export const Message = lazy(() => import('../pages/common/message'))
export const Search = lazy(() => import('../pages/common/search'))
export const Home = lazy(() => import('../pages/common/home'))
export const Questions = lazy(() => import('../pages/common/questions'))
export const QuestionsDetail = lazy(() => import('../pages/common/questionsDetail'))
export const PlateSearch = lazy(() => import('../pages/common/plateSearch'))


const commonRoutes = [
  {
    name: '协议',
    path: '/common/agree',
    component: Agree
  },
  {
    name: '消息中心',
    path: '/common/message',
    component: Message
  },
  {
    name: '搜索',
    path: '/common/search',
    component: Search
  },
  {
    name: '首页',
    path: '/common/home',
    component: Home
  },
  {
    name: '常见问题',
    path: '/common/questions',
    component: Questions
  },
  {
    name: '问题详情',
    path: '/common/questionsDetail',
    component: QuestionsDetail
  },
  {
    name: '板块搜索',
    path: '/common/plateSearch',
    component: PlateSearch
  },
]

export default commonRoutes