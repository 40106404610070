export const TYPES = {
  BASE: 'base', //基本面
  MODEL: 'modelList', //基础模型
  R_F: 'filterStockItems',//风险因子
  FORM:'form', //技术面
  LIMIT: 'limit', //区间
}

export const OUT_SELECT = [
  {
    label: '5个',
    value: 5,
  },
  {
    label: '10个',
    value: 10,
  },
  {
    label: '自定义',
    value: -1,
  },
]