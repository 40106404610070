
export const getCacheData = (key) => {
  let list = localStorage.getItem(key) || '[]';
  return JSON.parse(list) || [];
};
export const setCacheData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const clearAll = (key) => {
  localStorage.removeItem(key);
};
