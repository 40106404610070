import { lazy } from 'react';
// 活动相关路由
export const AssistanceCreate = lazy(() => import('../pages/activity/assistanceCreate'))
export const AssistanceDetail = lazy(() => import('../pages/activity/assistanceDetail'))
export const Attendance = lazy(() => import('../pages/activity/attendance'))
export const CouponDetail = lazy(() => import('../pages/activity/couponDetail'))
export const CouponCenter = lazy(() => import('../pages/activity/couponCenter'))

const activityRoutes = [
  {
    name: '好友助力创建', 
    path: '/activity/assistanceCreate',
    component: AssistanceCreate
  },
  {
    name: '好友助力详情',
    path: '/activity/assistanceDetail',
    component: AssistanceDetail
  },
  {
    name: '签到',
    path: '/activity/attendance',
    component: Attendance
  },
  {
    name: '优惠券详情',
    path: '/activity/couponDetail',
    component: CouponDetail
  },
  {
    name: '领劵中心',
    path: '/activity/couponCenter',
    component: CouponCenter
  }
]

export default activityRoutes